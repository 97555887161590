@import '~darrius/src/styles/mixins/media';

.new-budget-page {
  .best-pros,
  .cta-pro,
  .budgets,
  .pro-list,
  .average-prices,
  .similar-requests,
  .faq {
    @include media(normal) {
      padding: var(--size-spacing-stack-1400) 0;
    }

    padding: var(--size-spacing-stack-900) 0;
  }

  .main-section {
    .breadcrumb {
      @include media(normal) {
        margin-bottom: var(--size-spacing-stack-1000);
      }

      margin-bottom: var(--size-spacing-stack-700);
    }

    // Necessário para a responsividade em desktop, fazendo o título ficar próximo dos itens abaixo
    &__title-area {
      @include media(normal) {
        margin-bottom: -12rem;
      }

      @include media(large) {
        margin-bottom: -14rem;
      }
    }

    @media (max-width: 996px) {
      &__title {
        margin-bottom: 0;

        h1 {
          margin: 0;
          font-size: var(--size-font-2xl);
        }
      }

      &__subtitle {
        line-height: var(--font-line-height-normal);
        color: var(--color-neutral-dark-400);
        font-size: var(--size-font-base);
        font-weight: var(--font-weight-regular);
      }

      &__image {
        display: flex;

        img {
          object-fit: contain;
        }
      }
    }
  }

  .budget-similar-requests {
    @include media(normal) {
      .show-more-budget:not(:checked) {
        ~ .budget__request-card {
          &:nth-child(-n + 7) {
            display: block;
          }
        }
      }
    }

    .show-more-budget:not(:checked) {
      /* stylelint-disable selector-max-compound-selectors */
      ~ .button .less {
        display: none;
      }

      ~ .button .more {
        display: initial;
      }

      ~ .budget__request-card {
        display: none;

        &:nth-child(-n + 4) {
          display: block;
        }
      }
    }
    /* stylelint-enable selector-max-compound-selectors */

    .show-more-budget:checked {
      ~ .budget__request-card {
        display: block;
      }
    }
  }

  .form-and-ratings {
    padding: 0;

    .ratings {
      @include media(normal) {
        padding: 0 var(--size-spacing-inline-700);
      }

      padding: 0 var(--size-spacing-inline-300);
    }
  }

  @media (max-width: 996px) {
    .row.slider-sm {
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow-x: scroll;

      // Quando houver mais de 1, seleciona todos os filhos diretos.
      > *:not(:only-child) {
        margin-right: var(--size-spacing-inline-300);
        max-width: 85%;
      }
    }
  }
}
