@import '~darrius/build/styles';

@import './newBudgetPageAb.styles';
@import './home.styles';
@import './OverflowElement.styles';

.responsive-image {
  display: block;
  max-width: 100%;
  height: auto;
}

// Links do menu (ServicesCategories) e footer
.text-link__gray {
  height: 50px;

  &:not(:hover) {
    color: var(--shade-300);
  }

  &:hover {
    text-decoration: var(--none);
    color: var(--action-500);
  }
}

// Link List
.link-list {
  @include media(normal) {
    padding: var(--size-spacing-stack-700) 0;

    &__show-items-button {
      display: none;
    }
  }

  background-color: var(--color-neutral-light-200);
  padding: var(--size-spacing-stack-500) 0;

  &__items {
    @include media(normal) {
      grid-template-columns: repeat(4, 1fr);
    }

    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    list-style-type: var(--none, none);
    text-decoration: var(--none, none);
    gap: var(--size-spacing-stack-400);
  }

  a {
    color: var(--color-neutral-dark-300);

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
      color: var(--color-action-600);
    }
  }

  @media (max-width: 768px) {
    &.hide-items-mobile .link-list__item:nth-child(n + 11) {
      display: none;
    }
  }
}
