// /* stylelint-disable selector-max-compound-selectors */

@import '~darrius/src/styles/mixins/media';
@import '~darrius/src/styles/mixins/display';

// Bottom Links
.bottom-links {
  background-color: var(--shade-700);

  .button {
    justify-content: flex-start;
    font-size: 16px;
  }

  .show-more-1:not(:checked),
  .show-more-0:not(:checked) {
    ~ .button .less {
      display: var(--none, none);
    }

    ~ .button .more {
      display: initial;
    }

    ~ .link-group li {
      display: none;

      &:nth-child(-n + 10) {
        display: block;
      }
    }
  }

  .show-more-1:checked,
  .show-more-0:checked {
    ~ .link-group li {
      display: block;
    }
  }
}
