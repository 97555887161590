@import '~darrius/src/styles/mixins/media';

.overflow-visible {
  overflow: visible;
}

@mixin overflow-direction($viewport) {
  &.top-#{$viewport} {
    margin: 0;
    margin-top: var(--overflow-ammount-#{$viewport});
  }

  &.right-#{$viewport} {
    margin: 0;
    margin-right: var(--overflow-ammount-#{$viewport});
  }

  &.bottom-#{$viewport} {
    margin: 0;
    margin-bottom: var(--overflow-ammount-#{$viewport});
  }

  &.left-#{$viewport} {
    margin: 0;
    margin-left: var(--overflow-ammount-#{$viewport});
  }
}

.overflow-element {
  @include overflow-direction('sm');

  @include media(normal) {
    @include overflow-direction('md');
  }

  @include media(large) {
    @include overflow-direction('lg');
  }
}
